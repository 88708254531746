import React from 'react'
import Logo from '../../assets/img/UCCE_logo.png'
import CrcLogo from '../../assets/img/crclogo.png'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      <div className="logo">
        <img src={CrcLogo} alt="" />
      </div>
      <div className="copy">
        &copy; 2017 Regents of the University of California
      </div>
    </footer>
  )
}
