import React from 'react'

class MobileNav extends React.Component {
  state = {
    show: false,
  }

  componentDidUpdate(prevState) {
    const { show } = this.state
    if (prevState.show !== show) {
      if (typeof document !== 'undefined') {
        if (show) {
          document.body.classList.add('showMobileNav')
        } else {
          document.body.classList.remove('showMobileNav')
        }
      }
    }
  }

  _triggerNav = () => {
    const { show } = this.state
    this.setState({ show: !show })
  }

  render() {
    const { show } = this.state
    return (
      <React.Fragment>
        <a
          id="nav-toggle"
          href="#nav"
          aria-label="Open the menu"
          onClick={this._triggerNav}
          className={show ? 'active' : ''}
        >
          <span aria-hidden="true" />
        </a>

        <div className="mobileNav">
          <div className="mobileNavWrapper">
            <ul>
              <li>
                <a href="#identification" onClick={this._triggerNav}>
                  Identification
                </a>
              </li>
              <li>
                <a href="#prevention" onClick={this._triggerNav}>
                  Prevention
                </a>
              </li>
              <li>
                <a href="#sample" onClick={this._triggerNav}>
                  Sample Collection
                </a>
              </li>
              <li>
                <a href="#publications" onClick={this._triggerNav}>
                  Publications
                </a>
              </li>
              <li>
                <a href="#contact" onClick={this._triggerNav}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MobileNav
