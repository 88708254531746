import React from 'react'
import MobileNav from '../MobileNav'
import BackgroundImage from 'gatsby-background-image'
import Gplay from '../../assets/img/google-play-badge.png'

const Header = ({ siteTitle, hero }) => (
  <header>
    <div className="droidBanner">
      <div className="smartbanner-close">X</div>
      <p>We see that you are on an Android device</p>
      <a href="https://play.google.com/store/apps/details?id=edu.ucanr.weedyrice&hl=en">
        <img src={{ Gplay }} alt="Button: Get it on Google Play" />
      </a>
    </div>
    <nav>
      <div className="logo">
        <a href="#home">
          <h1>CALIFORNIA</h1>
          <h2>WEEDY RICE</h2>
        </a>
      </div>
      <div className="siteNav">
        <a href="#identification">Identification</a>
        <a href="#prevention">Prevention</a>
        <a href="#sample">Sample Collection</a>
        <a href="#publications">Publications</a>
        <a href="#contact">Contact</a>
      </div>
      <MobileNav />
    </nav>

    <BackgroundImage className="hero" fluid={hero.childImageSharp.fluid}>
      <div className="row">
        <div className="medium-6 medium-offset-6 columns">
          <div className="heroText">
            <p>Prevent and Eliminate the Infestation of</p>
            <h1>WEEDY RICE</h1>
          </div>
        </div>
      </div>
    </BackgroundImage>
  </header>
)

export default Header
